import { createUserWithEmailAndPassword } from 'firebase/auth';
import {
  addDoc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  startAt,
  endAt,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from '../firebase';
import { auth } from 'src/firebase';
import { toast } from 'react-hot-toast';

export async function createUserWithProfile({
  email,
  password,
  displayName,
  account_type = '',
  pictureUrl = 'null',
  status = 'Ativo',
  refId,
  can_live,
}) {
  try {
    const originalUser = auth.currentUser;

    await createUserWithEmailAndPassword(auth, email, password).then(async (userCreated) => {
      await addDoc(collection(firestore, 'profile'), {
        displayName: displayName,
        email: email,
        account_type: account_type,
        pictureUrl: pictureUrl,
        userId: userCreated.user.uid,
        status: status,
        refId: refId,
        // can_live: can_live || '',
      });

      auth.updateCurrentUser(originalUser, null);

      console.log('Document written with ID: ');
    });
  } catch (e) {
    toast.error(e?.message);
    console.error('Error adding document: ', e);
  }
}

export async function getUsers() {
  const result = [];
  const querySnapshot = await getDocs(collection(firestore, 'profile'));
  querySnapshot.forEach((doc) => result.push(doc.data()));
  return result;
}

export async function getActiveUsers() {
  const result = [];
  const querySnapshot = await getDocs(collection(firestore, 'profile'));
  querySnapshot.forEach((doc) => {
    if (doc.data().status === 'Ativo') result.push(doc.data());
  });
  return result;
}

export async function getUserByUid(uid) {
  const result = [];
  const q = query(collection(firestore, 'profile'), where('userId', '==', uid));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => result.push(doc.data()));
  return result;
}

export async function getUserByParams(params) {
  const result = [];
  const queries = [];
  const ref = collection(firestore, 'profile');

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      queries.push(query(ref, orderBy(key), startAt(params[key]), endAt(params[key] + '\uf8ff')));
    }
  });
  const q = [...queries];

  // const q = query(collection(firestore, 'profile'), where('email', '==', params.email));
  const querySnapshot = await getDocs(...q);
  querySnapshot.forEach((doc) => result.push(doc.data()));
  return result;
}

export async function createOrUpdateUserProfile(data) {
  try {
    if (data.userId) {
      const q = query(collection(firestore, 'profile'), where('userId', '==', data.userId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.log('Nenhum registro encontrado.');
      } else {
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await updateDoc(documentRef, data);
          console.log('Registro atualizado com sucesso:', doc.id);
        });
      }
    }
  } catch (e) {
    console.error('Erro ao buscar e excluir o registro:', e);
  }
}

export async function deleteSoftProfile(profile) {
  try {
    if (profile.userId) {
      const q = query(collection(firestore, 'profile'), where('userId', '==', profile.userId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        console.log('Nenhum registro encontrado.');
      } else {
        querySnapshot.forEach(async (doc) => {
          const documentRef = doc.ref;
          await updateDoc(documentRef, { status: 'Cancelado' });
          console.log('Registro cancelado com sucesso:', doc.id);
        });
      }
    }
  } catch (e) {
    console.error('Erro ao cancelar o registro:', e);
  }
}
