import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBuoSQNKM8FR1Nplqt4D8upf8lvdF99DTs',
  authDomain: 'hackerbet-3ee0a.firebaseapp.com',
  projectId: 'hackerbet-3ee0a',
  storageBucket: 'hackerbet-3ee0a.appspot.com',
  messagingSenderId: '113953354333',
  appId: '1:113953354333:web:1a68aeddbb738146f153b4',
};

const firebaseApp = initializeApp(firebaseConfig);

export const messaging = getMessaging(firebaseApp);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
